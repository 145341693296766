import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isAuth = this.authService.isAuthenticated();
    //console.log(this.authService.userRole);
    let cu =  JSON.parse(localStorage.getItem('currentUser'));
    if (!isAuth) {
      this.router.navigate(['/pages/login']);
    }
    else {
      console.log(route);
      
      console.log (state.url.startsWith('/admin') , cu.userRole );
      if (state.url.startsWith('/admin') && cu.userRole != 1) {
        this.router.navigate(['/pages/login']);
        return false
      } 
      else return true;
    }
  }
}
