export interface ApiResponse<T> {
    model: T;
    status: ResponseStatus;
    errorMessage: string;
    message: string;
    total: number;
}
export interface ApiResponseNoData {
    status: ResponseStatus;
    errorMessage: string;
    message: string;
}

export enum ResponseStatus {
    Success = 1,
    Fail
}
export class AuthApiResponse{
    token: string;
     user: string;
     name: string;
     status: number;
     message: string;
     firstName: string;
     lastName: string;
     userRole:number;
}