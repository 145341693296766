import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app'
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, ApiResponseNoData, AuthApiResponse } from 'app/services/models/apiResponse';

@Injectable()
export class AuthService {
  // private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  private userStorageName = Object.freeze("currentUser")

  private currentUser = localStorage.getItem(this.userStorageName);
  private tokenSubject = new BehaviorSubject(this.currentUser ? JSON.parse(this.currentUser).token : null);


  
  constructor( public router: Router, public http: HttpClient) {
    
    
    // this.user.subscribe(
    //   (user) => {
    //     if (user) {
    //       this.userDetails = user;
    //     }
    //     else {
    //       this.userDetails = null;
    //     }
    //   }
    // );

  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
    //return this._firebaseAuth.signInWithEmailAndPassword(email, password)
    return this.login(email,password);

  }
  login(email:string,password:string): Observable<AuthApiResponse> {
    const uri = `/api/auth/login`;
    let data = {email: email, password:password};
    return this.http.post<AuthApiResponse>(uri, data)
        .pipe(map(user => {
          console.log(user);
            if (user && user.status == 1) {
              console.log("ZZZZZZZZZZZZ");
              console.log(user);
              this.userRole=user.userRole;
                localStorage.setItem(this.userStorageName, JSON.stringify(user));
                this.tokenSubject.next(user.message);
            }

            return user;
        }));
}
public userRole=0;
  logout() {
    localStorage.clear();
    this.router.navigate(['/regions']);
  }

  isAuthenticated() {
    let user =  JSON.parse(localStorage.getItem(this.userStorageName));
    if (!user) return false;
    if (user.token) return true;
    
    return false;
  }
}
